<template>
    <div class="container">

        <div class="fl-x">
            <div class="col-lg-11">
                <h4 class="text-seventh text-center">PARVO</h4>
            </div>
            <div class="col-lg-1">
                <img src="../../../assets/user-home/like.png" class="img-fluid">
            </div>
        </div>
        <h6 class="text-2 pb-2">Contnet</h6>
        <p class="text-4 m-0 pb-1">Etiology</p>
        <p class="text-4 m-0 pb-1">Virus</p>
        <p class="text-4 m-0 pb-1">epidemiology</p>
        <p class="text-4 m-0 pb-1">Etiology</p>

        <div class="fl-x-cc mt-3 mb-4">
            <img src="../../../assets/user-home/bull1.png">
        </div>
        <h6 class="text-2 mt-4">Title</h6>
        <p class="text-4 m-0 fs--1 ">Virus</p>
        <h6 class="text-2 mt-4">Discription</h6>
        <p class="text-4 m-0 pb-1">Virus</p>
        <div class="fl-x-cc mt-3 mb-4">
            <img src="../../../assets/user-home/bull2.png">
        </div>

        <h6 class="pt-5 text-2">Treatment Suggestion</h6>

        <div class="col-lg-12 b-1 mt-3">
            <ExpandableCard title="Suggested by, Franklin"
                            description="Card Description goes here gfgxavcdgbdsndjf dvgfvdg gdvfgvdgfvbg"/>

        </div>
        <div class="col-lg-12 b-1 mt-3">
            <ExpandableCard title="Suggested by, Site"
                            description="Card Description goes here gfgxavcdgbdsndjf dvgfvdg gdvfgvdgfvbg"/>

        </div>
        <h6 class="pt-5 text-2">Gallery</h6>
        <div class="col-lg-12">
            <image-gallery></image-gallery>
        </div>
        <div class="row pt-5">
            <div class="col-lg-6">
                <validated-text-area placeholder="Enter Suggestion" label="Make Suggestion"></validated-text-area>
                <div class="fl-x-cc">
                    <btn color="secondary" border-radius="1">Suggest</btn>
                </div>
            </div>
            <div class="col-lg-6">
                <validated-text-area placeholder="Enter Matter" label="Report Disease"></validated-text-area>
                <div class="fl-x-cc">
                    <btn color="secondary" border-radius="1">Report</btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExpandableCard from '@components/cards/ExpandableCard';
import ImageGallery from '@components/user-home/Gallery';

export default {
    name: 'Individual-disease',
    components: { ImageGallery, ExpandableCard }
};
</script>

<style scoped>

</style>
