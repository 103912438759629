<template>
    <section class="py-5 full-width" :class="{pt9:$route.name !== 'Home'}">
        <div class="container">
            <div class="row py-4 px-0 text-center" v-if="$route.name == 'Home'" data-aos="fade-up"
                 data-aos-duration="1200">
                <div class="col-12 mb-4 mb-lg-0 fl-x fl-j-c">
                    <h5 class="text-danger lt-gp-2">
                        RECENT PROJECT'S GALLERY
                    </h5>
                </div>
            </div>

            <transition name="fade" mode="in-out">
                <div class="row mb-5">
                    <CoolLightBox
                        :items="imgItems"
                        :index="index"
                        @close="index = null">
                    </CoolLightBox>
                    <!--                <transition name="zoom" mode="out-in">-->
                    <template v-for="(i, imageIndex) in images">
                        <transition name="zoom" mode="in-out" v-bind:key="imageIndex">

                            <div class="col-12 col-lg-4 p-1 m-0 img-box"
                                 @click="index = imageIndex">
                                <div class="gal-img-contain" >
                                    <!--                                <img :src="require('@/assets/images/gallery/' + i.name)" alt="">-->
                                    <img :src="i.img" alt="" class="img-fluid">
                                    <div class="overlay-dark"></div>
                                </div>
                                <i class="fa fa-search-plus fa-2x photo-icon"></i>
                            </div>
                        </transition>
                    </template>
                </div>
            </transition>

            <div class="row" v-if="$route.name == 'Home'">
                <div class="col-12 text-center">
                    <router-link to="/gallery/" class="btn-plain text-semi-md">VIEW MORE</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
/* import img1 from '../../assets/user-home/bull1.png';
import img2 from '../../assets/user-home/bull2.png';
import img3 from '../../assets/user-home/bull3.png'; */
// import CoolLightBox from '@components/user-home/CoolLightBox';

export default {
    name: 'ImageGallery',
    components: { },
    props: {
        images: {

        }
    },
    data () {
        return {
            activeCateg: 'all',
            items: '', // filter to here
            imgItems: [],
            /* images: [
                { categ: 'deco', img: img1, name: 'gal1.jpg' },
                { categ: 'int', img: img2, name: 'gal2.jpg' },
                { categ: 'ext', img: img3, name: 'gal3.jpg' },
                { categ: 'ext', img: img3, name: 'gal4.jpg' },
                { categ: 'deco', img: img1, name: 'gal5.jpg' },
                { categ: 'int', img: img2, name: 'gal6.jpg' }

            ], */
            index: null
        };
    },
    mounted () {
        this.items = this.images;
        this.imgItems = [];
        this.images.forEach((item, i) => {
            console.log('item in prop', item);
            if (this.$route.name === 'Home' && i > 5) {
                return false;
            }
            this.imgItems.push(item.img);
        });

        if (this.$route.name === 'Gallery') {
            console.log('entered gallery');
            const headerLinksEls = document.getElementsByClassName('header-link');
            console.log('header links', headerLinksEls);
            Array.from(headerLinksEls).forEach(function (linkEl) {
                if (!linkEl.classList.contains('home-link')) {
                    linkEl.classList.add('display-none');
                }
            });
        }
    },
    methods: {
        setCateg (categ) {
            this.activeCateg = categ;
            if (categ === 'all') {
                this.items = this.images;
                this.imgItems = [];
                this.images.forEach((item, i) => {
                    console.log('image index', i);
                    if (this.$route.name === 'Home' && i > 5) {
                        return false;
                    }
                    this.imgItems.push(item.img);
                });
            } else {
                const itemsFiltered = this.images.filter(item => item.categ === this.activeCateg);
                this.imgItems = [];
                itemsFiltered.forEach((item, i) => {
                    if (this.$route.name === 'Home' && i > 5) {
                        return false;
                    }
                    this.imgItems.push(item.img);
                });
            }
        }
    }
};
</script>
<style lang="scss" scoped>

.img-box {
    position: relative;
    cursor: pointer;
    padding-bottom: 0 !important;

    .photo-icon {
        visibility: hidden;
        height: unset;
        display: none;
    }

    &:hover {
        .photo-icon {
            transition: all ease-in-out 300ms;
            position: absolute;
            top: 43%;
            left: 44%;
            bottom: 0;
            color: white;
            color: white;
            cursor: pointer;
            visibility: visible;
            height: unset;
            display: block;
        }

        .overlay-dark {
            visibility: visible;
        }
    }
}

.gal-img-contain {
    position: relative;
    height: 100% !important;

    img {
        width: 100%;
    }

    &:hover {

    }

}

.fade-left-enter-active, .fade-left-leave-active {
    transition: opacity .5s;
}

.fade-left-enter, .fade-left-leave-to /* .fade-left-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.fade-right-enter-active, .fade-right-leave-active {
    transition: opacity .5s;
}

.fade-right-enter, .fade-right-leave-to /* .fade-left-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.zoom-enter-active, .zoom-leave-active {
    transform: scale(1);
    opacity: 1;
    transition: all .5s;
}

.zoom-enter, .zoom-leave-to /* .fade-left-leave-active below version 2.1.8 */
{
    opacity: 0;
    transform: scale(0.6);
}

.afterFulWidth {
    transition: all 0.5s;

    &::after {
        width: 200%;
        opacity: 1;
        transition: all 0.5s;
    }

    &:hover {
        color: var(--color-danger);
    }
}

.mt9 {
    padding-top: 9rem;
}

.overlay-dark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.48);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
    bottom: 20 !important;
    visibility: hidden;
}
</style>
